export const ApplicationAuditData = [
  {
    id: 1,
    title: "1. Discovery",
    description:
      "In-depth analysis of application logic, structure, workflows, and functionality.",
    categories: "Audit",
  },
  {
    id: 2,
    title: "2. Performance Optimization",
    description:
      "Identify and fix weak points for better speed and efficiency.",
    categories: "Audit",
  },
  {
    id: 3,
    title: "3. Error Reduction",
    description: "Remove broken tables, fields, and reports to prevent bugs.",
    categories: "Audit",
  },
  {
    id: 4,
    title: "4. Tune-Up",
    description: "Eliminate redundant filters, columns, and calculations.",
    categories: "Audit",
  },
  {
    id: 5,
    title: "5. Scalability Assessment",
    description: "Ensure applications can grow with your business.",
    categories: "Audit",
  },
  {
    id: 6,
    title: "6. User Experience Evaluation",
    description: "Gather feedback to improve adoption and satisfaction.",
    categories: "Audit",
  },
  {
    id: 7,
    title: "7. Integration Check",
    description: "Review and optimize system integrations.",
    categories: "Audit",
  },
  {
    id: 8,
    title: "8. Notification Review",
    description:
      "Reduce noise and ensure relevant updates reach the right people.",
    categories: "Audit",
  },
  {
    id: 9,
    title: "9. Documentation Review",
    description: "Improve documentation for easier maintenance.",
    categories: "Audit",
  },
  {
    id: 10,
    title: "10. Future Roadmap Suggestions",
    description: "Recommend upgrades to maintain long-term efficiency.",
    categories: "Audit",
  },
]
