import { graphql } from "gatsby"
import { Link, useStaticQuery } from "gatsby"
import React from "react"
import Layout from "../components/layout"

import Seo from "../components/seo"
import Testimonials2 from "../components/swiper/testimonial-swiper"
import PartnerSwiper from "../components/swiper/swiper-partner.js"
import { LazyLoadComponent } from "react-lazy-load-image-component"
import "react-lazy-load-image-component/src/effects/blur.css"
import { useContext } from "react"
import Banner from "../components/hero/banner-hometeam.js"

import Hero from "../components/hero/hero-application-health-audit.js"
import pMinDelay from "p-min-delay"
import loadable from "@loadable/component"

import {
  Accordion,
  AccordionContext,
  Card,
  useAccordionToggle,
} from "react-bootstrap"
import { ApplicationAuditData } from "../components/internal-data/health-audit-data.js"

import ItConsulting from "../components/multi-tab/health-audit-multi-tab.js"
import CaseStudySection from "../components/header/header-casestudy-section"

import Cases from "../components/cases"

import UnlockEnterprise from "../components/unlock-enterprise.js"

class ApplicationHealthAuditPage extends React.Component {
  render() {
    const siteTitle = this.props.data.site.siteMetadata.title
    const siteDescription = this.props.data.site.siteMetadata.description
    return (
      <Layout
        location={this.props.location}
        title={siteTitle}
        banner={<Hero />}
      >
        <Seo
          title="Quickbase Application Health Audit | Quandary Consulting Group"
          description="Our comprehensive Quickbase application health audit helps you maximize the value of your infrastructure, improve performance, and reduce technical debt."
        />
        <div id="main">
          <LazyLoadComponent>
            <PartnerSwiper />
            <ItConsulting />
            <section
              className="faq-wrapper security-audit"
              style={{ padding: "1.5rem 0 4.5rem" }}
            >
              <div className="container">
                <div className="row">
                  <div className="col-lg-9 mx-auto">
                    <h2 className="mt-0 text-center">
                      Our 10-Step Process for Running Your Application Health
                      Audit
                    </h2>
                    {ApplicationAuditData.map(account => (
                      <Accordion defaultActiveKey="0" key={account.id}>
                        <Card className="accordion-body">
                          <ContextAwareToggle
                            eventKey={account.id}
                            className="bg-transparent"
                          >
                            {account.title}
                          </ContextAwareToggle>
                          <Accordion.Collapse eventKey={account.id}>
                            <Card.Body>
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: account.description,
                                }}
                              />
                            </Card.Body>
                          </Accordion.Collapse>
                        </Card>
                      </Accordion>
                    ))}
                  </div>
                </div>
              </div>
            </section>
            <Testimonials2 />
            <CaseStudySection />
            <CaseComponent dataSrc={this.props.data.allSanityCaseStudy} />
            <Banner />
            <UnlockEnterprise />
            <section
              className="bg-case-study"
              style={{
                paddingTop: "5rem",
                paddingBottom: "5rem",
              }}
            >
              <div className="container">
                <div className="row">
                  <div className="col-lg-10 mx-auto text-center text-left-mobile">
                    <div>
                      <h2 className="text-white">
                        Are You Getting The Most Out of Your Quickbase
                        Ecosystems?
                      </h2>
                      <Link
                        to="/contact/"
                        className="btn btn-primary btn-arrow mr-2 mt-4 text-center py-3 text-uppercase"
                        style={{ marginTop: "auto" }}
                      >
                        LET'S CHAT
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </LazyLoadComponent>
        </div>
      </Layout>
    )
  }
}

export default ApplicationHealthAuditPage

function ContextAwareToggle({ children, eventKey, callback }) {
  const currentEventKey = useContext(AccordionContext)

  const decoratedOnClick = useAccordionToggle(
    eventKey,
    () => callback && callback(eventKey)
  )

  const isCurrentEventKey = currentEventKey === eventKey

  return (
    <Card.Header
      className={isCurrentEventKey ? "accordion-active" : "accordion-collapsed"}
    >
      <button
        aria-label="btn"
        type="button"
        onClick={decoratedOnClick}
        className={`m-0 border-0 bg-transparent w-100 text-left ${
          isCurrentEventKey ? "text-primary-gradient" : ""
        }`}
      >
        <p className="h5 text-left toggle-btn">{children}</p>
        <div>
          <div className="btn-plus-minus" />
        </div>
      </button>
    </Card.Header>
  )
}

const CaseComponent = ({ dataSrc }) => {
  return (
    <section id="case-studies-container">
      <div id="case-studies" className="bg-case-study py-5">
        <div className="container d-none">
          <div className="intro text-center">
            <div className="row">
              <div className="col-md-10 mx-auto">
                {/* <p
                  className="h5 text-light-blue text-uppercase font-weight-bold m-0"
                  style={{ fontFamily: "Futura,sans-serif" }}
                >
                  Case Studies
                </p>
                <h2 className=" my-2 text-white">
                  We Save Clients 1000s of Hours. Every Year.
                </h2> */}
                {/* <p className="text-white">
                  We helped a national healthcare provider save over 9,000 hours
                  in weekly labor costs by developing a single application...
                </p> */}
              </div>
            </div>
          </div>
        </div>
        {dataSrc && <Cases allSanityCaseStudy={dataSrc} />}
        <div className="text-center">
          <Link
            className="btn btn-primary btn-arrow mr-2 mt-4 text-center"
            to="/case-studies/"
          >
            More Case Studies
          </Link>
        </div>
      </div>
    </section>
  )
}

export const QuickbaseSecurityAuditPagePageQuery = graphql`
  query ApplicationHealthAuditPagePageQuery {
    site {
      siteMetadata {
        title
        author
        description
      }
    }
    allSanityCaseStudy(sort: { fields: [publishedAt], order: DESC }, limit: 3) {
      edges {
        node {
          title
          publishedAt(formatString: "MMMM DD, YYYY")
          mainImage {
            asset {
              gatsbyImageData(
                fit: FILL
                placeholder: BLURRED
                sizes: ""
                layout: CONSTRAINED
              )
            }
          }
          bannerImage {
            asset {
              gatsbyImageData(
                fit: FILL
                placeholder: BLURRED
                sizes: ""
                layout: CONSTRAINED
              )
            }
          }
          slug {
            current
          }
        }
      }
    }
  }
`
