import React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { LazyLoadComponent } from "react-lazy-load-image-component"
import "swiper/css/swiper.css"
import useMediaQuery from "../../hooks/useMediaQuery"

const ItConsulting = () => {
  const isDesktop = useMediaQuery("(min-width: 768px)")

  return (
    <section
      id="it-consulting"
      className="it-consulting"
      style={{ padding: "1.5rem 0" }}
    >
      <div className="container">
        <div className="row">
          <div className="col-lg-12 col-md-8 mx-auto text-center">
            <h2 className="my-3 font-weight-medium">
              Maximize the Value of Your Quickbase Applications
            </h2>
            <p className="mx-auto" style={{ maxWidth: "825px" }}>
              Your Quickbase applications need regular maintenance and
              improvements to deliver the maximum value to your organization.
            </p>
          </div>
        </div>
        {isDesktop ? (
          <LazyLoadComponent>
            <div class="tabs pt-4 health-audit">
              <input
                type="radio"
                class="tabs__radio"
                name="tabs-example"
                id="tab1"
                defaultChecked
              />
              <label
                for="tab1"
                class="tabs__label mr-3 ml-auto mt-2 text-center"
              >
                Comprehensive App Assessment
              </label>
              <div class="tabs__content">
                <div
                  className="row align-items-center mt-3 mb-5"
                  style={{ boxShadow: "rgba(0, 0, 0, 0.16) 0px 3px 100px" }}
                >
                  <div className="col-lg-6 col-md-6 mt-4 mb-4">
                    <StaticImage
                      placeholder="blurred"
                      className="rounded d-flex w-100"
                      src="https://cdn.sanity.io/images/55lcecww/production/85d5a701a77aa45ff2a4ea2b239b1547d9d38b46-2559x1691.jpg"
                      alt="Comprehensive App Assessment"
                      layout="constrained"
                      style={{ height: "20rem" }}
                      loading="lazy"
                      fetchpriority="low"
                    />
                  </div>
                  <div className="col-lg-6 col-md-6 text-lg-left text-md-left text-center align-self-center mb-lg-0 mb-md-0 mb-5 pl-md-5">
                    <h3 className="text-left-mobile">
                      Comprehensive App Assessment
                    </h3>
                    <p className="my-4 text-left-mobile">
                      Our audit covers the custom code, infrastructure,
                      architecture, integrations, permissions, and processes of
                      your Quickbase applications.
                    </p>
                  </div>
                </div>
              </div>
              <input
                type="radio"
                class="tabs__radio"
                name="tabs-example"
                id="tab2"
              />
              <label
                for="tab2"
                class="tabs__label ml-3 mr-auto text-center mt-2"
              >
                Maximize Platform Performance
              </label>
              <div class="tabs__content">
                <div
                  className="row align-items-center flex-column-reverse flex-lg-row flex-md-row mt-3 mb-5"
                  style={{ boxShadow: "rgba(0, 0, 0, 0.16) 0px 3px 100px" }}
                >
                  <div className="col-lg-6 col-md-6 text-lg-left text-md-left mt-lg-0 mt-md-0 mt-5 text-center mb-lg-0 mb-md-0 mb-5 pl-md-5">
                    <h3 className="text-left-mobile">
                      Maximize Platform Performance
                    </h3>
                    <p className="my-4 text-left-mobile">
                      Our recommendations make your applications more efficient,
                      getting you more out of the Quickbase platform.
                    </p>
                  </div>
                  <div className="col-lg-6 col-md-6 my-lg-4 my-md-4 my-0 mt-lg-0 mt-md-0 mt-5">
                    <StaticImage
                      placeholder="blurred"
                      className="rounded d-flex w-100"
                      src="https://cdn.sanity.io/images/55lcecww/production/4bce9b3641f675d842e83a0f72c0ef9903011b70-6144x4069.jpg"
                      alt="Maximize Platform Performance"
                      layout="constrained"
                      style={{ height: "20rem" }}
                      loading="lazy"
                      fetchpriority="low"
                    />
                  </div>
                </div>
              </div>
              <input
                type="radio"
                class="tabs__radio"
                name="tabs-example"
                id="tab3"
              />
              <label
                for="tab3"
                class="tabs__label mr-3 ml-auto mt-2 text-center"
              >
                Seamless Roadmap Integration
              </label>
              <div class="tabs__content">
                <div
                  className="row align-items-center mt-3 mb-5"
                  style={{ boxShadow: "rgba(0, 0, 0, 0.16) 0px 3px 100px" }}
                >
                  <div className="col-lg-6 col-md-6 mt-4 mb-4">
                    <StaticImage
                      placeholder="blurred"
                      className="rounded d-flex w-100"
                      src="https://cdn.sanity.io/images/55lcecww/production/192a0caf62f61b150f8c895f69d5764feacab9c1-3648x5472.jpg"
                      alt=" Seamless Roadmap Integration"
                      layout="constrained"
                      style={{ height: "20rem" }}
                      loading="lazy"
                      fetchpriority="low"
                    />
                  </div>
                  <div className="col-lg-6 col-md-6 text-lg-left text-md-left text-center align-self-center mb-lg-0 mb-md-0 mb-5 pl-md-5">
                    <h3 className="text-left-mobile">
                      Seamless Roadmap Integration
                    </h3>
                    <p className="my-4 text-left-mobile">
                      We prioritize critical Quickbase application improvements
                      while minimizing disruption to keep your business goals on
                      track.
                    </p>
                  </div>
                </div>
              </div>
              <input
                type="radio"
                class="tabs__radio"
                name="tabs-example"
                id="tab4"
              />
              <label
                for="tab4"
                class="tabs__label ml-3 mr-auto text-center mt-2"
              >
                Consistency Across Applications
              </label>
              <div class="tabs__content">
                <div
                  className="row align-items-center mt-3 mb-5"
                  style={{ boxShadow: "rgba(0, 0, 0, 0.16) 0px 3px 100px" }}
                >
                  <div className="col-lg-6 col-md-6 mt-4 mb-4">
                    <StaticImage
                      placeholder="blurred"
                      className="rounded d-flex w-100"
                      src="https://cdn.sanity.io/images/55lcecww/production/82b5ba6419c0f5c09a39cd4af001219e3309e5f2-6000x4004.jpg"
                      alt=" Consistency Across Applications"
                      layout="constrained"
                      style={{ height: "20rem" }}
                      loading="lazy"
                      fetchpriority="low"
                    />
                  </div>
                  <div className="col-lg-6 col-md-6 text-lg-left text-md-left text-center align-self-center mb-lg-0 mb-md-0 mb-5 pl-md-5">
                    <h3 className="text-left-mobile">
                      Consistency Across Applications
                    </h3>
                    <p className="my-4 text-left-mobile">
                      Multiple developers create inconsistencies. Ensure uniform
                      branding, data structures, access, and UX across your
                      Quickbase ecosystem.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </LazyLoadComponent>
        ) : (
          <div className="mobile-swiper d-flex flex-column pt-2">
            <LazyLoadComponent>
              <div className="pt-md-5 pb-md-5">
                <div>
                  <div className="col-12 text-center">
                    <h3 className="">Comprehensive App Assessment</h3>
                    <p className="my-4 ">
                      Our audit covers the custom code, infrastructure,
                      architecture, integrations, permissions, and processes of
                      your Quickbase applications.
                    </p>
                  </div>
                  <div className="col-12">
                    <img
                      placeholder="blurred"
                      className="rounded d-flex w-100 img-fluid"
                      src="https://cdn.sanity.io/images/55lcecww/production/85d5a701a77aa45ff2a4ea2b239b1547d9d38b46-2559x1691.jpg"
                      alt="Comprehensive App Assessment"
                      layout="constrained"
                      loading="lazy"
                      fetchpriority="low"
                    />
                  </div>
                </div>
              </div>
              <div className="pt-md-5 pb-md-5">
                <div>
                  <div className="col-12 text-center">
                    <h3 className="">Maximize Platform Performance</h3>
                    <p className="my-4 ">
                      Our recommendations make your applications more efficient,
                      getting you more out of the Quickbase platform.
                    </p>
                  </div>
                  <div className="col-12">
                    <StaticImage
                      placeholder="blurred"
                      className="rounded d-flex w-100"
                      src="https://cdn.sanity.io/images/55lcecww/production/4bce9b3641f675d842e83a0f72c0ef9903011b70-6144x4069.jpg"
                      alt="Maximize Platform Performance"
                      layout="constrained"
                      style={{ height: "20rem" }}
                      loading="lazy"
                      fetchpriority="low"
                    />
                  </div>
                </div>
              </div>
              <div className="pt-md-5 pb-md-5">
                <div>
                  <div className="col-12 text-center">
                    <h3 className="">Seamless Roadmap Integration</h3>
                    <p className="my-4 ">
                      We prioritize critical Quickbase application improvements
                      while minimizing disruption to keep your business goals on
                      track.
                    </p>
                  </div>
                  <div className="col-12">
                    <img
                      placeholder="blurred"
                      className="rounded d-flex w-100"
                      src="https://cdn.sanity.io/images/55lcecww/production/192a0caf62f61b150f8c895f69d5764feacab9c1-3648x5472.jpg"
                      alt=" Seamless Roadmap Integration"
                      layout="constrained"
                      loading="lazy"
                      fetchpriority="low"
                    />
                  </div>
                </div>
              </div>
              <div className="pt-md-5 pb-md-5">
                <div>
                  <div className="col-12 text-center">
                    <h3 className="">Consistency Across Applications</h3>
                    <p className="my-4 ">
                      Multiple developers create inconsistencies. Ensure uniform
                      branding, data structures, access, and UX across your
                      Quickbase ecosystem.
                    </p>
                  </div>
                  <div className="col-12">
                    <img
                      placeholder="blurred"
                      className="rounded d-flex w-100"
                      src="https://cdn.sanity.io/images/55lcecww/production/82b5ba6419c0f5c09a39cd4af001219e3309e5f2-6000x4004.jpg"
                      alt=" Consistency Across Applications"
                      layout="constrained"
                      loading="lazy"
                      fetchpriority="low"
                    />
                  </div>
                </div>
              </div>
            </LazyLoadComponent>
          </div>
        )}
      </div>
    </section>
  )
}

export default ItConsulting
