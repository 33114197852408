import React from "react"

import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import "./hero.css"
import "./homepage-hero.css"

const HealthAuditBanner = () => {
  return (
    <>
      <div className="hero-banner relative pt-4 overflow-hidden">
        <div className="hero-overlay relative">
          <StaticImage
            placeholder="none"
            className="wave-img"
            src="./img/wave-white-right.png"
            alt="System Integration"
            layout="constrained"
            style={{ height: "fit-content", zIndex: 0 }}
            fetchpriority="low"
          />
        </div>
        <div className="container relative my-auto" style={{ zIndex: 1 }}>
          <div className="row pb-3 align-items-center">
            <div className="col-lg-7 col-xl-7 col-md-7">
              <div
                className="hero-custom-content-home review-content text-white"
                style={{ padding: "20px 0", marginTop: "5.3rem" }}
              >
                <h5 className="text-light-blue text-uppercase font-weight-bold mt-0 mb-4 text-left">
                  Quickbase Services
                </h5>
                <h1
                  className="page-title text-left"
                  style={{
                    marginBottom: "0.5rem",
                    display: "inline",
                  }}
                >
                  Find and Eliminate Quickbase Application Inefficiencies
                </h1>
                <p className="mb-0 mt-2 text-left">
                  We help businesses increase Quickbase application performance
                  and optimize their infrastructure with our comprehensive
                  application health audit.
                </p>

                <div className="hero-button">
                  <Link
                    to="/contact"
                    className="btn btn-primary btn-arrow mr-2 mt-4 text-center text-capitalize py-3"
                    style={{ marginTop: "auto" }}
                  >
                    LET'S CHAT
                  </Link>
                </div>
              </div>
            </div>
            <div
              className="col-lg-5 col-md-5 pb-2 d-flex justify-content-end"
              style={{ position: "relative" }}
            >
              <StaticImage
                placeholder="none"
                className="rounded d-flex w-100"
                src="https://cdn.sanity.io/images/55lcecww/production/ae4604d3bc0e25f353b937633c93214896df3ec0-1800x1800.png"
                alt="System Integration"
                layout="constrained"
                style={{ height: "fit-content", zIndex: 1 }}
                fetchpriority="low"
              />
            </div>
          </div>
        </div>
        <div className="swiperOverlay"></div>
      </div>
    </>
  )
}

export default HealthAuditBanner
